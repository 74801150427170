export const meta = {
  title: 'Студентите - услуги в областта на образованието',
  description: 'Ууслуги в областта на образованието. Разработване на курсова и дипломна работа, писане на реферати, есета и казуси, взимане на изпити и др.',
  canonical: 'https://www.studentite.com',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'разработване, изработка, дипломна работа, магистърска теза, бакалавърска теза, курсова работа, цена, казус, реферат, доклад, есе, задача, стаж, домашна работа, математика, статисика, моделиране, тест, изпит, проект'
    }
  }
};

export const mastersMeta = {
  title: 'Магистри • Разработка и съдесйтвие при магистратура',
  description: 'Съдействие при магистърска програма. Разработване на курсова и дипломна работа, взимане на изпити, дистанционно обучение...',
  canonical: 'https://www.studentite.com/masters/',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'разработване, изработка, дипломна работа, магистърска теза, курсова работа, цена, казус, реферат, доклад, есе, стаж, статисика, тест, изпит, проект, дистанционно обучение'
    }
  }
};

export const bacheloresMeta = {
  title: 'Бакалаври • Разработка и съдесйтвие при бакалавърска програма',
  description: 'Съдействие при бакалавърска програма. Разработване на курсова и дипломна работа, взимане на изпити, дистанционно обучение...',
  canonical: 'https://www.studentite.com/bachelores/',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'разработване, изработка, дипломна работа, бакалавърска теза, курсова работа, цена, казус, реферат, доклад, есе, задача, стаж, статисика, тест, изпит, проект, дистанционно обучение'
    }
  }
};

export const studentsMeta = {
  title: 'Ученици • Решаване на задачи и тестове',
  description: 'Специализиран сайт за услуги в областта на образованието. Съдействие за домашни работи, математика, статистика, решаване на тестове, excel и powerpoint.',
  canonical: 'https://www.studentite.com/students/',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'реферат, доклад, есе, задача, домашна работа, математика, статисика, моделиране, тест, изпит, проект'
    }
  }
};

export const aboutUsMeta = {
  title: 'За нас • Студентите - услуги в областта на образованието',
  description: 'Професионални помощ при разработването на теми, магистърски тези, дипломни и курсови работи, реферати, есета, казуси, задачи, въпроси и др.',
  canonical: 'https://www.studentite.com/about-us/',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'съдействие, разработка, реферат, доклад, цена, есе, задача, домашна работа, математика, статисика, моделиране, тест, изпит, проект'
    }
  }
};

export const contactsMeta = {
  title: 'Контакти • Потърсете съдействие за вашето образование',
  description: 'Свържете се с нас за професионални помощ при разработването на теми, магистърски тези, дипломни и курсови работи, реферати, есета, казуси, задачи, въпроси и др.',
  canonical: 'https://www.studentite.com/contacts/',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'съдействие, разработка, реферат, доклад, цена, есе, задача, домашна работа, математика, статисика, моделиране, тест, изпит, проект, образование, висше'
    }
  }
};

export const privacyPolicyMeta = {
  title: 'Политика за поверителност • Студентите - услуги в областта на образованието',
  description: 'Професионални помощ при разработването на теми, магистърски тези, дипломни и курсови работи, реферати, есета, казуси, задачи, въпроси и др.',
  canonical: 'https://www.studentite.com/privacy-policy/',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'съдействие, разработка, реферат, доклад, цена, есе, задача, домашна работа, математика, статисика, моделиране, тест, изпит, проект'
    }
  }
};

export const faqMeta = {
  title: 'Често задавани въпроси • Студентите - услуги в областта на образованието',
  description: 'Разработване на теми, магистърски тези, дипломни и курсови работи, писане на реферати, есета, казуси, решаване на задачи за изпити, въпроси и др.',
  canonical: 'https://www.studentite.com/faq/',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'изготвяне, съдействие, разработка, реферат, доклад, цена, есе, задача, домашна работа, математика, статисика, моделиране, тест, изпит, проект'
    }
  }
};

export const errorMeta = {
  title: '404 Грешка • Студентите - услуги в областта на образованието',
  description: 'Професионални помощ при разработването на теми на магистърски тези, дипломни и курсови работи, реферати, есета, казуси, задачи, въпроси и др.',
  canonical: 'https://www.studentite.com/error/',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'съдействие, разработка, реферат, доклад, есе, задача, домашна работа, математика, статисика, моделиране, тест, изпит, проект, образование, висше'
    }
  }
};
