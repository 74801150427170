import { Image } from 'antd';
import React from 'react';

const CusImage = ({src, alt, frameStyle}) => {
  return (
    <div className="image">
      <Image preview={false} src={src} alt={alt} />
      <div className={`frame ${frameStyle}`} />
    </div>
  )
}

export default CusImage;