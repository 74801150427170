import React from 'react';
import {Carousel, Button} from 'antd';
import {Link} from 'react-router-dom';
import Section from './Section';
import Overlay from './Overlay';

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(require.context('../img/carousel/', false, /\.(png|jpe?g|svg)$/));

const MainHeader = () => {
  return (
    <div className="hero">
      <Carousel autoplay effect="fade" dots={false}>
        {images.map((image) => {
          return (
            <Section key={image.default} wrapperStyle={{backgroundImage: `url(${image.default})`}}></Section>
          );
        })}
      </Carousel>
      <Overlay />
      <Section>
        <h1>Специализиран сайт за услуги в областта на образованието</h1>
        <Button size="large" shape="round" type="primary" style={{marginRight: '5px'}}><Link to="/contacts/">Свържете се с нас</Link></Button>
        <Button size="large" ghost shape="round"><Link to="/about-us/">Повече за нас</Link></Button>
      </Section>
    </div>
  );
};

export default MainHeader;