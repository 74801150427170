import React from 'react';
import {Row} from 'antd';

const Section = ({children, background, style, wrapperStyle, className}) => {
  return (
    <div style={wrapperStyle} className={`wrapper ${background ? "light-background" : ""} ${className}`}>
      <Row style={style} className="section" align="middle" gutter={24}>
        {children}
      </Row>
    </div>  
  )
}

export default Section;