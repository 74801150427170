import "core-js";
import React, {Suspense} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { hydrate, render } from "react-dom";

const reactApp = (
  <React.StrictMode>
    <Router>
      <Suspense fallback={<div className="loading" />}>
        <App />
      </Suspense>
    </Router>
  </React.StrictMode>
);
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(reactApp, rootElement);
} else {
  render(reactApp, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
