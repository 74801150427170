import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Layout, Menu, Row, Col} from 'antd';
import {CallToAction} from './';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPhoneSquareAlt, faMailBulk, faBars} from '@fortawesome/free-solid-svg-icons';

const {Header, Content, Footer} = Layout;

const Navigation = ({children}) => {
  const location = useLocation();

  return (
    <Layout>
      <Row style={{ position: 'fixed', zIndex: 999, width: '100%' }}>
        <Col xs={24} className="small-header">
          <a href="tel:+359899104090" style={{paddingRight: "15px"}} className="no-style"><FontAwesomeIcon icon={faPhoneSquareAlt} /> 0899 104090</a>
          <a href="mailto:office@studentite.com" className="no-style"><FontAwesomeIcon icon={faMailBulk} /> office@studentite.com</a>
        </Col>
        <Col xs={24}>
          <Header className="site-navigation">
            <Link to="/"><h2 className="logo">Студентите</h2></Link>
            <Menu mode="horizontal" selectedKeys={[location.pathname.replace(/\//g,'')]} overflowedIndicator={<FontAwesomeIcon icon={faBars} />}>
              <Menu.Item key="about-us">
                <Link to="/about-us/">За нас</Link>
              </Menu.Item>
              <Menu.Item key="masters">
                <Link to="/masters/">Магистри</Link>
              </Menu.Item>
              <Menu.Item key="bachelores">
                <Link to="/bachelores/">Бакалаври</Link>
              </Menu.Item>
              <Menu.Item key="students">
                <Link to="/students/">Ученици</Link>
              </Menu.Item>
              <Menu.Item key="contacts">
                <Link to="/contacts/">Контакти</Link>
              </Menu.Item>
              <Menu.Item key="faq">
                <Link to="/faq/">ЧЗВ</Link>
              </Menu.Item>
            </Menu>
          </Header>
        </Col>
      </Row>
      <Content>{children}</Content>
      <CallToAction />
      <Footer>Студентите ©{new Date().getFullYear()} | <Link to="/privacy-policy/">Политика за поверителност</Link> | <Link to="/faq/">Често зададвани върпоси</Link></Footer>
    </Layout>
  );
}

export default Navigation;
