import React from 'react';

const Overlay = (props) => {
  const style = {
    backgroundColor: 'rgb(0, 0, 0)',
    opacity: '0.5',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  }
  return <div className="overlay" style={{...style, ...props.style}} />
};

export default Overlay;