import React from 'react';

const PageHeader = ({title}) => {
  return (
    <div className="site-header" align="center">
      <h1>{title}</h1>
      <p className="subheader"><em>Разработване на курсови и дипломни работи, писане на реферати, есета, тези и казуси, решаване на задачи за изпити и тестове.</em></p>
      <div className="overlay" />
    </div>
  );
};

export default PageHeader;