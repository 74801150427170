import React from 'react';
import {Link} from 'react-router-dom';
import {Col, Button} from 'antd';
import {Section} from '.';

const CallToAction = () => {
  return (
    <div className="call-to-action">
      <Section>
        <Col xs={24} md={17} lg={19}>
          <h3>Професионален и индивидуален подход!</h3>
        </Col>
        <Col xs={24} md={7} lg={5} align="right">
        <Link to="/contacts/"><Button name="contact-us">Свържете се с нас</Button></Link>
        </Col>
      </Section>
    </div>
  )
};

export default CallToAction;