import React, {Suspense, useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import {useLocation} from "react-router";
import DocumentMeta from 'react-document-meta';
import {meta} from './constants/meta';
import './App.css';
import Navigation from './common/Navigation';

const Home = React.lazy(() => import('./views/Home'));
const Students = React.lazy(() => import('./views/Students'));
const Bachelores = React.lazy(() => import('./views/Bachelores'));
const Masters = React.lazy(() => import('./views/Masters'));
const AboutUs = React.lazy(() => import('./views/AboutUs'));
const Contacts = React.lazy(() => import('./views/Contacts'));
const Faq = React.lazy(() => import('./views/FAQ'));
const ErrorPage = React.lazy(() => import('./views/ErrorPage'));
const PrivacyPolicy = React.lazy(() => import('./views/PrivacyPolicy'));

const App = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <DocumentMeta {...meta}>
      <Navigation>
        <Suspense fallback={<div className="loading" />}>
          <Switch>
            <Route path={`/about-us`} render={(props) => <AboutUs {...props} />} />
            <Route path={`/masters`} render={(props) => <Masters {...props} />} />
            <Route path={`/bachelores`} render={(props) => <Bachelores {...props} />} />
            <Route path={`/students`} render={(props) => <Students {...props} />} />
            <Route path={`/contacts`} render={(props) => <Contacts {...props} />} />
            <Route path={`/faq`} render={(props) => <Faq {...props} />} />
            <Route path={`/privacy-policy`} render={(props) => <PrivacyPolicy {...props} />} />
            <Route exact path={`/`} render={(props) => <Home {...props} />} />
            <Route component={ErrorPage} />
          </Switch>
        </Suspense>
      </Navigation>
    </DocumentMeta>
  );
}

export default App;
